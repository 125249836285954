import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { Section, SectionTitle, MarkdownContent } from "../../components/Section"
import { TwoColumns } from "../../components/Grid"

const AboutProbuilt = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "about-probuilt.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img fluid={data.placeholderImage.childImageSharp.fluid} alt='Probuilt Steel Buildings' />
}

class HistorySection extends React.Component {
	render(){
    const { data } = this.props
		return(
			<Section pt="100px" pb="150px">
      	<div className="container">
      		<TwoColumns>
      			<div>
      				<AboutProbuilt />
      			</div>
      			<div>
      				<SectionTitle textAlign="left">
      					{data.title}
      				</SectionTitle>
      				<MarkdownContent dangerouslySetInnerHTML={{__html: data.description.childMarkdownRemark.html}} />
      			</div>
      		</TwoColumns>
      	</div>
      </Section>
		)
	}
}

export default HistorySection