import React, { Component } from 'react'
import styled from 'styled-components'

import { Section, SectionTitle, MarkdownContent } from "../../components/Section"
import Img from "gatsby-image"

const PeopleGrid = styled.div`
	margin:0;
`
const PeopleCard = styled.div`
	display: flex;
	flex-wrap:wrap;
    align-items:center;
	margin:0 -10px;
`
const PeopleFigure = styled.div`
	position:relative;
	width:100%;
	padding:0 10px;
	@media(min-width:576px){
		min-width:200px;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}
`
const PeopleBody = styled.div`
	position:relative;
	width:100%;
	padding:0 10px;
	@media(min-width:576px){
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;
	}
	& h3 {
		font-size: 34px;
		line-height: 41px;
		margin-top: 17px;
		margin-bottom: 5px;
	}
	& h5 {
		font-size: 16px;
		line-height: 26px;
		color: #676B79;
		margin-bottom: 12px;
	}
	& p {
		font-size: 16px;
		line-height: 28px;
		text-align: left;
	}
`

class PeoplesSection extends Component {
	render() {
		const { data, peopleData } = this.props
		return(
			<Section pt="116px" pb="80px">
				<div className="container">
					<SectionTitle textAlign="left" ml="0px">{data.title} </SectionTitle>
					<MarkdownContent dangerouslySetInnerHTML={{__html: data.description.childMarkdownRemark.html}} />
					<PeopleGrid>
						{
							peopleData.edges.map((item, i)=>{
								return(
									<PeopleCard>
										<PeopleFigure>
											<Img fluid={item.node.image.fluid} alt={`photo of ${item.node.name}`} />
										</PeopleFigure>
										<PeopleBody>
											<h3>{item.node.name}</h3>
											<h5>{item.node.role}</h5>
											<p>{item.node.description?.description}</p>
										</PeopleBody>
									</PeopleCard>
								)
							})
						}
					</PeopleGrid>
				</div>
			</Section>
		)
	}
}

export default PeoplesSection