import React from 'react'
import styled from 'styled-components'
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CallToAction from "../components/CallToAction"
import { TwoColumns } from "../components/Grid"
import AboutVission from "../components/Icons/AboutVission"
import AboutMission from "../components/Icons/AboutMission"

import AboutHero from "../sections/About/AboutHero"
import HistorySection from "../sections/About/HistorySection"
import HowWorks from "../sections/About/HowWorks"
import FeaturesSection from "../sections/Home/FeaturesSection"
import PeoplesSection from "../sections/About/PeoplesSection"

const Bdr = styled.div`
  display: inline-block;
  border-bottom: 92px solid white;
  border-left: 127vw solid transparent;
  margin-top: -92px;
  margin-left: -27vw;
`
const BdrSection = styled.div`
	position: relative;

	@media(max-width: 768px){
  	display: none;
  }
`
const HalfSection = styled.div`
	background: ${props=>props.bg};
	height: 600px;
	padding: 15%;
	& div {
		text-align: center;
	}
	& h2 {
		font-size: 54px;
		line-height: 64px;
		margin-top: 48px;
		margin-bottom: 17px;
		color: white;
	}
	& p {
		color: white;
		font-size: 18px;
		line-height: 30px;
	}
	@media(max-width: 768px) {
		height: auto;
	}
`
const AboutPage = (pageData) =>{
	const PeopleData = pageData.data.allContentfulPeople
	const AboutHeroData = pageData.data.contentfulAbout.aboutHero
	const AboutHistoryData = pageData.data.contentfulAbout.aboutHistory
	const AboutVisionData = pageData.data.contentfulAbout.aboutMissionVission
	const AboutHowWorksData = pageData.data.contentfulAbout.aboutHowWorks
	const AboutUspsData = pageData.data.contentfulAbout.aboutUsps
	const AboutPeopleData = pageData.data.contentfulAbout.aboutPeople
	return(
		<Layout location={pageData.location}>
      <SEO title={pageData.data.contentfulAbout.metaTitle} description={pageData.data.contentfulAbout.metaDescription} />
      <AboutHero data={AboutHeroData} />
      <BdrSection>
      	<Bdr />
      </BdrSection>
      <HistorySection data={AboutHistoryData} />
      <TwoColumns>
      	<HalfSection bg="#1E4156">
      		<div>
      			<AboutMission />
      		</div>
      		<h2>{AboutVisionData.features[0].title}</h2>
      		<p>{AboutVisionData.features[0].description}</p>
      	</HalfSection>
      	<HalfSection bg="#010920">
      		<div>
      			<AboutVission />
      		</div>
      		<h2>{AboutVisionData.features[1].title}</h2>
      		<p>{AboutVisionData.features[1].description}</p>
      	</HalfSection>
      </TwoColumns>
      <HowWorks data={AboutHowWorksData} />
      <FeaturesSection data={AboutUspsData} />
      <PeoplesSection peopleData={PeopleData} data={AboutPeopleData} />
      <CallToAction />
    </Layout>
	)
}

export default AboutPage

export const pageQuery = graphql`
  query AboutPageQuery {
  	contentfulAbout {
	    title
	    metaTitle
	    metaDescription
	    aboutHero {
	      title
	      description {
	        childMarkdownRemark {
	          html
	        }
	      }
	    }
	    aboutHistory {
	      title
	      description {
	        childMarkdownRemark {
	          html
	        }
	      }
	    }
	    aboutMissionVission {
	      features {
	        image
	        name
	        description
	      }
	    }
	    aboutHowWorks {
	      title
	      description {
	        childMarkdownRemark {
	          html
	        }
	      }
	      features {
	      	name
	      	description
	      }
	    }
	    aboutUsps {
	      title
	      description {
	        childMarkdownRemark {
	          html
	        }
	      }
	      features {
	      	title
	      	image
	        description
	      }
	    }
	    aboutPeople {
	      title
	      description {
	        childMarkdownRemark {
	          html
	        }
	      }
	    }
	  }
	  allContentfulPeople {
	    edges {
	      node {
	        name
	        role
	        description {
	          description
	        }
	        image {
	          fluid(maxWidth: 300, quality: 100) {
	            base64
	            sizes
	            src
	            srcSet
	            srcSetWebp
	            srcWebp
	            aspectRatio
	          }
	        }
	      }
	    }
	  }
	}
`